import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class ApifaceModelService {
   private modelsLoaded = new BehaviorSubject(false);
   modelsReady = this.modelsLoaded.asObservable();
   constructor() {
      this.modelsLoaded.next(false);
   }

   changeValue(bool: boolean): void {
      this.modelsLoaded.next(bool);
   }
}

import { FuseConfig } from '@fuse/types';
import * as env from '../../environments/environment';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
   // Color themes can be defined in src/app/app.theme.scss
   colorTheme: 'theme-default',
   customScrollbars: true,
   layout: {
      style: 'vertical-layout-1',
      width: 'fullwidth',
      navbar: {
         primaryBackground: 'fuse-navy-700',
         secondaryBackground: 'fuse-navy-900',
         folded: false,
         hidden: true,
         position: 'left',
         variant: 'vertical-style-1',
      },
      toolbar: {
         customBackgroundColor: false,
         background: 'fuse-white-500',
         hidden: true,
         position: 'below-static',
      },
      footer: {
         customBackgroundColor: true,
         background: 'fuse-navy-900',
         hidden: true,
         position: 'below-fixed',
      },
      sidepanel: {
         hidden: true,
         position: 'right',
      },
   },
   // Datos para EP /oauth/token
   client_id: 'DH65dHoDu9ApHXjzV6KcqFwbGs5QrjX8RPAK4bgGJU',
   client_secret: 'LdYyXHuwKXrARViMLR9hmHzprsp22ToPcuVwCKpgVDU',
   audience: 'https://api.waiver.com',
   grant_type: 'client_credentials',
   // ID: 'fHOPXH3hJFCm3OskEBVvsUOkQyvZZPsz21vWVA2JV7o',
   
   //for local testing, api on port 3000
   // URL: 'http://localhost:3000/api/v1/',
   URL: !env.environment.production
   ? 'https://dev.waiver.binwus.com/app/api/v1/'
   : '/app/api/v1/',
   CAPTCHA_URL: !env.environment.production
      ? 'https://captcha.waiver.binwus.com/'
      : 'https://captcha.rainforestadventure.com/',
   App_Version: 'v1.0.0',
   App_Authorization: '',
   Authorization: '',
   park: {
      id: '',
      code: '',
      name: '',
      logo: '',
   },
   user: {
      token: '',
      scope: '',
      role: '',
      language: '',
      timezone: '',
      username: '',
      email: '',
      phone: '',
      first_name: '',
      last_name: '',
      photo: '',
   },
};

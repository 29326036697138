import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { environment as env } from '../environments/environment';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

// import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { PipesCustomModule } from 'app/pipes/pipes.module';
import { BackendInterceptor } from './interceptors/backend.interceptor';

const appRoutes: Routes = [
    {
        path: 'waiver',
        loadChildren: './main/waiver/waiver.module#WaiverModule',
    },
    {
        path: 'auth',
        loadChildren:
            './main/authentication/authentication.module#AuthenticationModule',
    },
    {
        path: '**',
        redirectTo: 'waiver',
    },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,
        PipesCustomModule,

        // Memory API
        // env.production ? [] : InMemoryWebApiModule.forRoot(FakeDbService),
    ],
    providers: [
        // {
        //    provide: HTTP_INTERCEPTORS,
        //    useClass: BackendInterceptor,
        //    multi: true,
        // },
        { provide: LOCALE_ID, useValue: 'en-US' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseConfig } from '../../fuse-config';

@Injectable({
   providedIn: 'root',
})
export class OauthService {
   public isAuthenticated = false;
   constructor(
      private http: HttpClient,
      private fuseConfigService: FuseConfigService
   ) {}

   /**
    * Genera token de acceso a las aplicaciones clientes (client_credentials)
    * @param body JSON
    */
   postToken(body: any): Observable<any> {
      return this.http.post(`${fuseConfig.URL}oauth/token`, body, {
         headers: {
            'Content-Type': 'application/json',
         },
         observe: 'response',
      });
   }

   /**
    * Autentica al usuario.
    * @param body JSON
    */
   postAuth(body: any): Observable<any> {
      return this.http.post(`${fuseConfig.URL}oauth/authenticate`, body, {
         observe: 'response',
         headers: {
            'Content-Type': 'application/json',
            'App-Authorization': this.fuseConfigService.config.source._value
               .App_Authorization,
            'App-Version': this.fuseConfigService.config.source._value
               .App_Version,
         },
      });
   }

   /**
    * Inicio de registro de un usuario, solicita email
    * @param body JSON
    */
   postRequestcode(body: any): Observable<any> {
      return this.http.post(`${fuseConfig.URL}oauth/requestcode`, body, {
         observe: 'response',
         headers: {
            'Content-Type': 'application/json',
            'App-Authorization': this.fuseConfigService.config.source._value
               .App_Authorization,
            'App-Version': this.fuseConfigService.config.source._value
               .App_Version,
         },
      });
   }

   /**
    * Finaliza de registro de un usuario, solicita email
    * @param body JSON
    */
   postFinishregister(body: any): Observable<any> {
      return this.http.post(`${fuseConfig.URL}oauth/finishregister`, body, {
         observe: 'response',
         headers: {
            'Content-Type': 'application/json',
            'App-Authorization': this.fuseConfigService.config.source._value
               .App_Authorization,
            'App-Version': this.fuseConfigService.config.source._value
               .App_Version,
         },
      });
   }

   /**
    * Finaliza el cambio de password que comenzó con /oauth/requestpasswordchange.
    * @param body JSON
    */
   postRequestpass(body: any): Observable<any> {
      return this.http.post(
         `${fuseConfig.URL}oauth/requestpasswordchange`,
         body,
         {
            observe: 'response',
            headers: {
               'Content-Type': 'application/json',
               'App-Authorization': this.fuseConfigService.config.source._value
                  .App_Authorization,
               'App-Version': this.fuseConfigService.config.source._value
                  .App_Version,
            },
         }
      );
   }

   /**
    * Marca la entrada al parque del adulto.
    * @param body JSON
    */
   postFinishpass(body: any): Observable<any> {
      return this.http.post(
         `${fuseConfig.URL}oauth/finishpasswordchange`,
         body,
         {
            observe: 'response',
            headers: {
               'Content-Type': 'application/json',
               'App-Authorization': this.fuseConfigService.config.source._value
                  .App_Authorization,
               'App-Version': this.fuseConfigService.config.source._value
                  .App_Version,
            },
         }
      );
   }
}

import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html';
import { SafeUrlPipe } from './safe-url';
import { ShowTimePipe } from './show-time';

@NgModule({
   declarations: [SafeHtmlPipe, SafeUrlPipe, ShowTimePipe],
   imports: [],
   exports: [SafeHtmlPipe, SafeUrlPipe, ShowTimePipe],
})
export class PipesCustomModule {}
